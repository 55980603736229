
import * as mediavaletRuntime$aygWYlxMhx from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as ipxRuntime$das3yd392X from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['mediavalet']: { provider: mediavaletRuntime$aygWYlxMhx, defaults: {"baseURL":"https://playa-media.imgix.net"} },
  ['ipx']: { provider: ipxRuntime$das3yd392X, defaults: {} }
}
        